<template>
  <v-dialog
    v-model="isPopupVisible"
    persistent
    max-width="95vw"
  >
    <v-card>
      <div
        class="contactProfilePopupWrapper"
      >
        <div class="messageComposerHeader">
          <h1 class="popupHeaderLabel">
            Contact profile details
          </h1>
          <!-- <h1>{{ contact.firstName }} {{ contact.lastName }}</h1>
          <h2>{{ contact.cellNumber }}</h2> -->
          <button
            class="closeBtn"
            @click="_hidePopup"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </button>
        </div>
        <div class="contactProfileBody">
          <v-container
            id="contact-profile"
            tag="section"
          >
            <!--  Show if message/group not selected  -->
            <div
              v-if="isLoaderActive"
              class="messageLoader"
            >
              <div class="loader" />
            </div>

            <v-row v-if="!isLoaderActive && isMessageDataAvailable">
              <v-col
                cols="12"
              >
                <div class="messageInfoWrapper">
                  <div class="messageMetaData">
                    <h3>Contact Name: {{ selectedContact.firstName + " " + selectedContact.lastName }}</h3>
                    <h3>CellNumber: {{ selectedContact.cellNumber }}</h3>
                    <h3>Email: {{ selectedContact.email }}</h3>
                    <p class="statsData">
                      Number of deliveries: {{ stats.delivered }}
                    </p>
                    <p>Failed deliveries: {{ stats.failed }}</p>
                    <p>Undelivered messages: {{ stats.undelivered }}</p>
                  </div>
                  <div class="graphWrapper">
                    <!-- <div id="chart" /> -->
                    <!-- <div
                      v-if="isPieChartVisible"
                      class="pieChartWrapper"
                    >
                      <pie-chart :items="pieChartData" />
                    </div> -->
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row v-if="!isLoaderActive && isMessageDataAvailable">
              <v-col
                cols="12"
                md="3"
                offset-md="3"
                offset-lg="3"
                class="statusFilterWrapper"
              >
                <v-select
                  v-model="selectedGroupId"
                  :items="groups"
                  item-text="name"
                  item-value="id"
                  label="Select a group"
                  solo
                  class="dropdown"
                  @change="updateGroupSelection"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
                class="statusFilterWrapper"
              >
                <v-select
                  v-model="selectedStatus"
                  :items="status"
                  item-text="name"
                  item-value="id"
                  label="Filter by status"
                  solo
                  class="dropdown"
                  @change="filterByStatus"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
                class="statusFilterWrapper"
              >
                <v-select
                  v-model="selectedMessageType"
                  :items="messageTypes"
                  item-text="name"
                  item-value="id"
                  label="Filter by message type"
                  solo
                  class="dropdown"
                  @change="updateMessageTypes"
                />
              </v-col>
              <v-col
                cols="12"
              >
                <v-data-table
                  :headers="headers"
                  :items="allMessages"
                  item-key="id"
                  sort-by="Status"
                >
                  <template
                    v-slot:item.Message="{ item }"
                  >
                    {{ item.Message }}
                  </template>
                  <template v-slot:item.language="{ item }">
                    <div class="senderActions">
                      <div
                        class="mr-1"
                      >
                        <v-btn
                          color="primary"
                          outlined
                          x-small
                          @click="translateTxt('en', item)"
                        >
                          en
                        </v-btn>
                      </div>
                      <div
                        class="mr-1"
                      >
                        <v-btn
                          color="primary"
                          outlined
                          x-small
                          @click="translateTxt('zh', item)"
                        >
                          zh
                        </v-btn>
                      </div>
                      <div
                        class="mr-1"
                      >
                        <v-btn
                          color="primary"
                          outlined
                          x-small
                          @click="translateTxt('es', item)"
                        >
                          es
                        </v-btn>
                      </div>
                    </div>
                  </template>
                  <template v-slot:no-data>
                    <p class="mb-4 mt-4">
                      No messages to display
                    </p>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
  import HttpConnector from '../../../../utils/http-connector'
  import EventEmitter from '../../../../utils/event-emitter'
  import { Chart } from 'frappe-charts/dist/frappe-charts.esm.js'
  // import css
  import 'frappe-charts/dist/frappe-charts.min.css'
  import moment from 'moment'
  const { gapi } = window

  export default {
    name: 'ContactProfilePopup',
    components: {
    },
    props: {},
    data: () => ({
      httpConnector: new HttpConnector(),
      apiEndpoint: process.env.VUE_APP_API_BASE_URL,
      apiCode: process.env.VUE_APP_API_CODE,
      snackbar: false,
      notification: 'Unknown',
      isPopupVisible: false,
      isLoaderActive: false,
      googleTranslateApiKey: process.env.VUE_APP_GOOGLE_TRANSLATE_API,
      googleTranslateClientId: process.env.VUE_APP_GOOGLE_TRANSLATE_API_CLIENT_ID,
      dialog: false,
      selectedLanguage: 'en',
      headers: [
        { text: 'Cell Number', value: 'CellNumber', width: '10%' },
        { text: 'Date Sent', value: 'dateSent', width: '10%' },
        { text: 'Message', value: 'Message', width: '40%' },
        { text: 'Language', value: 'language', width: '15%' },
        { text: 'Group', value: 'GroupName', width: '15%' },
        { text: 'Sender', value: 'CellFrom', width: '5%' },
        { text: 'Status', value: 'status', width: '5%' },
        // { text: 'Status Message', value: 'ErrorMessage' },
      ],
      isOverlayVisible: false,
      selectedGroup: {
        id: -1,
        name: 'All Groups',
      },
      selectedGroupId: -1,
      selectedMessageType: '1',
      selectedMessage: null,
      selectedContact: null,
      selectedStatus: 'delivered',
      contacts: [],
      groups: [],
      messages: [],
      isMessageDataAvailable: false,
      sentMessages: [],
      failedMessages: [],
      deliveredMessages: [],
      undeliveredMessages: [],
      allMessages: [],
      status: [{
        name: 'Delivered',
        id: 'delivered',
      }, {
        name: 'Failed',
        id: 'failed',
      }, {
        name: 'Undelivered',
        id: 'undelivered',
      }],
      messageTypes: [{
        name: 'Message Sent',
        id: '1',
      }, {
        name: 'Responses',
        id: '2',
      }],
      pieChartData: [],
      isPieChartVisible: false,
      stats: null,
    }),
    computed: {},
    watch: {
      isPopupVisible (val) {
        if (!val) {
        //   this._clearInterval()
        } else {
        //   this._initiateMessageCheckInBackground()
        }
      },
    },

    async created () {
      this.usermail = localStorage.getItem('username')
      EventEmitter.subscribe('show-contact-profile-popup', (contact) => {
        // this.selectedContact = contact
        this.isPopupVisible = true
        this.isLoaderActive = true
        this.updateContactSelection(contact)
      })
    },

    methods: {
      async initialize () {
        gapi.load('client:auth2', this.initClient)
      },

      async fetchGroupByContactId (id) {
        try {
          this.isOverlayVisible = true
          // First fetch all available groups
          const { data = [] } = await this.httpConnector.makeRequest('get', `${this.apiEndpoint}contact/${id}/groups?code=${this.apiCode}`)

          this.groups = []
          this.groups.push({
            id: -1,
            name: 'All Groups',
          })
          data.forEach((group) => {
            this.groups.push({
              id: group.GroupID,
              name: group.Name,
            })
          })
          this.isOverlayVisible = false
        } catch (error) {
          this.isOverlayVisible = false
        }
      },

      async translateTxt (lang, item) {
        this.selectedLanguage = lang

        const { body } = await gapi.client.language.translations.list({
          q: item.Message,
          target: lang,
        })

        const response = JSON.parse(body)
        item.Message = response.data.translations[0].translatedText

        this.allMessages.splice(item.id, 1, item)
      },

      initClient () {
        const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/translate/v2/rest']

        const SCOPES = ['https://www.googleapis.com/auth/cloud-platform', 'https://www.googleapis.com/auth/cloud-translation', 'https://www.googleapis.com/auth/plus.business.manage', 'https://www.googleapis.com/auth/business.manage']
        const { gapi } = window
        gapi.client.init({
          apiKey: this.googleTranslateApiKey,
          clientId: this.googleTranslateClientId,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES,
        }).then(() => {}, (e) => {})
      },

      async fetchStats (groupId = this.selectedContact.groupId) {
        // Fetch stats data for selected contact
        const stats = await this.httpConnector.makeRequest('get', `${this.apiEndpoint}contacthistorycnt/${this.selectedContact.cellNumber}/${groupId}?code=${this.apiCode}`)
        const sent = stats.data.find(stat => stat.StatusMessage === 'sent')
        const delivered = stats.data.find(stat => stat.StatusMessage === 'delivered')
        const failed = stats.data.find(stat => stat.StatusMessage === 'failed')
        const undelivered = stats.data.find(stat => stat.StatusMessage === 'undelivered')
        this.stats = {
          sent: sent && sent.Count ? sent.Count : 0,
          delivered: delivered && delivered.Count ? delivered.Count : 0,
          failed: failed && failed.Count ? failed.Count : 0,
          undelivered: undelivered && undelivered.Count ? undelivered.Count : 0,
        }
      },

      async updateGroupSelection (selectedGroupId) {
        this.selectedGroupId = selectedGroupId
        this.selectedGroup = this.groups.find(g => g.id === selectedGroupId)

        if (this.selectedGroupId === -1) {
          await this.fetchStats(this.selectedContact.GroupID)
          this.loadPieChart()

          this.allMessages = this.formatMessages([
            ...this.failedMessages,
            ...this.deliveredMessages,
          ])
        } else {
          await this.fetchStats(selectedGroupId)
          this.loadPieChart()

          const messagesArr = [
            ...this.failedMessages,
            ...this.deliveredMessages,
          ]
          const groupedMsg = messagesArr.filter((item) => {
            return item.GroupName.toLowerCase() === this.selectedGroup.name.toLowerCase()
          })

          this.allMessages = this.formatMessages([
            ...groupedMsg,
          ])
        };
      },

      async updateContactSelection (selectedContact) {
        console.log('selectedContact :>> ', selectedContact)
        this.isLoaderActive = true
        // this.isMessageDataAvailable = false
        this.selectedContact = selectedContact
        // Fetch all messages of selected contact
        this.isOverlayVisible = true
        await Promise.all([
          this.fetchMessagesOfSelectedContact(),
          this.fetchStats(),
          this.fetchGroupByContactId(selectedContact.id),
        ])
        this.isMessageDataAvailable = true
        // this.loadLineGraph()
        this.loadPieChart()
        this.selectedGroup = {
          id: -1,
          name: 'All Groups',
        }
        this.selectedGroupId = -1
        this.isLoaderActive = false
      },

      async updateMessageTypes (newMessageType) {
        // Reset status selection
        this.selectedStatus = 'delivered'
        this.selectedMessageType = newMessageType
        this.selectedGroup = {
          id: -1,
          name: 'All Groups',
        }
        this.selectedGroupId = -1
        await this.fetchMessagesOfSelectedContact()
      },

      async fetchMessagesOfSelectedContact () {
        this.isOverlayVisible = true
        console.log('this.selectedContact :>> ', this.selectedContact)
        try {
          const params = {
            CellNumber: this.selectedContact.cellNumber,
            MessageType: this.selectedMessageType,
          }
          console.log('params :>> ', params)
          // if (this.selectedContact.GroupID) {
          //   params.GroupID = this.selectedContact.GroupID
          // }
          const insightResponse = await Promise.all([
            this.httpConnector.makeRequest('post', `${this.apiEndpoint}GetContactSMSHistory?code=${this.apiCode}`, {
              ...params,
              Status: 'delivered',
            }),
            this.httpConnector.makeRequest('post', `${this.apiEndpoint}GetContactSMSHistory?code=${this.apiCode}`, {
              ...params,
              Status: 'failed',
            }),
            this.httpConnector.makeRequest('post', `${this.apiEndpoint}GetContactSMSHistory?code=${this.apiCode}`, {
              ...params,
              Status: 'undelivered',
            }),
          ])
          this.deliveredMessages = insightResponse[0].data.map(m => {
            return { ...m, status: 'delivered' }
          })
          this.failedMessages = insightResponse[1].data.map(m => {
            return { ...m, status: 'failed' }
          })
          this.undeliveredMessages = insightResponse[2].data.map(m => {
            return { ...m, status: 'undelivered' }
          })
          this.allMessages = this.formatMessages([
            ...this.deliveredMessages,
          ])
          this.isOverlayVisible = false
        } catch (error) {
          this.isOverlayVisible = false
        }
      },

      loadPieChart () {
        this.isPieChartVisible = false
        setTimeout(() => {
          // Prepare pe chart data
          this.pieChartData = [{
            label: 'Delivered',
            backgroundColor: '#00f07c',
            value: this.stats.delivered,
          }, {
            label: 'Failed',
            backgroundColor: '#eb4034',
            value: this.stats.failed,
          }, {
            label: 'Undelivered',
            backgroundColor: '#f56942',
            value: this.stats.undelivered,
          }]
          this.isPieChartVisible = true
        }, 200)
      },

      loadLineGraph () {
        setTimeout(() => {
          this.chart = new Chart('#chart', {
            title: 'Campaign Statistics ',
            data: this.chartData,
            type: 'axis-mixed', // or 'bar', 'line', 'scatter', 'pie', 'percentage'
            height: 250,
            colors: ['#427ef5', '#00f07c', '#f00044'],
          })
        }, 1000)
      },

      formatMessages (messages) {
        return messages.map((message, index) => {
          return {
            ...message,
            id: index,
            dateSent: moment(message.CreateDate).format('MM-DD-YYYY'),
          }
        })
      },

      formatMessagesByGroup (messages) {
        if (this.selectedGroup.id === -1) return messages
        const groupedMsg = messages.filter((item) => {
          return item.GroupName.toLowerCase() === this.selectedGroup.name.toLowerCase()
        })
        return groupedMsg
      },

      filterByStatus (status) {
        this.selectedStatus = status
        switch (status.toLowerCase()) {
          case 'delivered': {
            this.allMessages = this.formatMessages([
              ...this.deliveredMessages,
            ])
            this.allMessages = this.formatMessagesByGroup(this.allMessages)
            break
          }
          case 'failed': {
            this.allMessages = this.formatMessages([
              ...this.failedMessages,
            ])
            this.allMessages = this.formatMessagesByGroup(this.allMessages)
            break
          }
          case 'undelivered': {
            this.allMessages = this.formatMessages([
              ...this.undeliveredMessages,
            ])
            this.allMessages = this.formatMessagesByGroup(this.allMessages)
            break
          }
          default: {
            this.allMessages = this.formatMessages([
              ...this.failedMessages,
              ...this.deliveredMessages,
            ])
          }
        }
      },
      _hidePopup () {
        this._reset()
        this.isPopupVisible = false
      },
      _reset () {
        this.isPopupVisible = false
        this.isLoaderActive = false
      },
    },
  }
</script>
<style lang="scss" scoped>
.v-dialog {
    overflow-x: hidden ;
}
.contactProfilePopupWrapper {
  min-height: 800px;
  width: 95vw;
  .messageComposerHeader {
    width: 100%;
    height: 150px;
    background-color: #db8400;
    padding: 0rem 6rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    .closeBtn {
        position: absolute;
        top: 3rem;
        right: 3rem;
        width: 30px;
      height: 30px;
      border-radius: 30px;
      background-color: rgb(211, 0, 0);
      * {
        color: white;
      }
    }
  }

    .contactProfileBody {
        background: rgb(255, 255, 255);
        width: 100%;
    }

}
  .loader,
  .loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
  .loader {
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(240, 164, 0, 0.2);
    border-right: 1.1em solid rgba(240, 164, 0, 0.2);
    border-bottom: 1.1em solid rgba(240, 164, 0, 0.2);
    border-left: 1.1em solid #f0a400;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
</style>

<style scoped lang="scss">
  .messageInfoWrapper {
    width: 100%;
    background-color: white;
    min-height: 100px;
    max-height: 280px;
    display: flex;
    @media only screen and (max-width: 968px) {
        display: block;
    }
    .messageMetaData {
      width: 50%;
      padding: 40px 20px 30px 60px;
      @media only screen and (max-width: 968px) {
        width: 100%;
      }
      h3 {
        margin-bottom: 6px;
      }
      .dateDisplay {
        margin-bottom: 75px;
      }
      p {
        margin-bottom: 3px;
      }
    }
    .graphWrapper {
      width: 50%;
      @media only screen and (max-width: 968px) {
        width: 100%;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      .pieChartWrapper {
        width: 280px;
      }
    }
  }
  .mr-1 {
    margin-right: 5px;
  }
  .senderActions {
    display: flex;
  }
  .selectMessageInfo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 150px;
    margin-bottom: 200px;
  }
    .spinner {
    margin-left: 17rem;
  }
  @media only screen and (max-width: 968px) {
    .spinner {
      margin-left: 0;
    }
  }
  .statusFilterWrapper {
    margin-top: 25px;
    margin-bottom: -30px;
  }
  .orLabel {
    text-align: center;
    // align-items: center;
    padding-top: 25px;
  }
  .reset {
    color: red;
    cursor: pointer;
    text-align: center;
  }
  .statsData {
    margin-top: 5rem;
  }
</style>
